import React from "react";

const Button = (props) => {
	return (
		<div
			onClick={props.onClickFn}
			className='bg-blue-600 hover:bg-blue-800 cursor-pointer m-3 w-[350px] max-w-[380px] basis-1 self-center text-center text-white font-bold py-3 px-4 rounded-full padding-3'>
			Continue
		</div>
	);
};

export default Button;
