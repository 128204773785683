import React from "react";
import { MultiSelect } from "react-multi-select-component";
import { useContext } from "react";
import { ParamsContext } from "../contexts/ParamsContext";
const MultiSelectOption = () => {
	const { params, setParams, selected, setSelected } =
		useContext(ParamsContext);
	let options = [];

	for (let i = 0; i < params.length; i++) {
		options.push({ label: params[i], value: params[i] });
	}
	return (
		<div className='flex flex-col gap-5'>
			<h1 className='mt-5 text-2'>Select Params</h1>

			<MultiSelect
				options={options}
				value={selected}
				onChange={setSelected}
				labelledBy='Select'
				className='mt-3'
			/>
		</div>
	);
};

export default MultiSelectOption;
