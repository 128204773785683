import React, { useState } from "react";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router";
const Logout = ({ setSigned, setMountLogin }) => {
	const navigate = useNavigate();
	const [logged, setLogged] = useState(true);
	const cookies = new Cookies();
	const handleLogin = () => {
		cookies.remove("access_token");
		setLogged(false);
		setSigned(false);
		setMountLogin(true);
		navigate("/");
	};
	return logged ? (
		<div
			onClick={handleLogin}
			className='text-white font-smitchSans p-5 w-32 fixed  text-center bg-blue-600 hover:bg-blue-800 cursor-pointer z-10  right-2 top-2  opacity-7 h-8 text-xl block  py-0 rounded-full'>
			Logout
		</div>
	) : (
		<></>
	);
};

export default Logout;
