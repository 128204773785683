import React from "react";
import Otp from "./Otp";

const OtpField = ({ email, setSigned }) => {
	return (
		<div className='relative font-smitchSans flex min-h-screen flex-col justify-center overflow-hidden bg-gray-50 pt-12'>
			<div className='relative bg-white border px-6 pt-10 pb-9 shadow-inner mx-auto w-full max-w-lg rounded-2xl'>
				<div className='mx-auto flex w-full max-w-md flex-col space-y-16'>
					<div className='flex flex-col items-center justify-center text-center space-y-2'>
						<div className='font-semibold text-3xl'>
							<p>OTP Verification</p>
						</div>
						<div className='flex flex-row text-sm font-medium text-gray-400'>
							<p>We just sent a verification code to {email}</p>
						</div>
					</div>

					<div>
						<form action='' method='post'>
							<div className='flex flex-col space-y-16'>
								<Otp email={email} setSigned={setSigned} />
								<div className='flex flex-col space-y-5'></div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default OtpField;
