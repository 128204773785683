import React from "react";
import tablet_icon from "../../assets/icons/Tablet.png";
const MedicineCard = ({ props }) => {
	return (
		<>
			<div className='flex items-center flex-row w-[100%]'>
				<div className='basis-4/12 flex flex-row items-center gap-5 '>
					<img className='w-8' src={tablet_icon} alt='' />
					<span className='text-xs'>{props.name}</span>
				</div>
				<div className='flex flex-col justify-center items-center basis-2/12'>
					<span className='text-xs lg:text-sm text-yellow-500'>
						{props.medication_adherence} %
					</span>
					<span className='text-xs lg:text-sm'>Adherence</span>
				</div>
				<div className='flex flex-col justify-center items-center basis-2/12'>
					<span className='text-xs lg:text-sm'>
						{props.medication_occurrence_taken}/
						{props.medication_occurrence_total}
					</span>
					<span className='text-xs lg:text-sm'>Medicines Taken</span>
				</div>
				<div className='flex flex-col justify-center items-center basis-2/12'>
					<span className='text-red-500 font-semibold text-xs lg:text-sm'>
						{props.medication_days_missed}
					</span>
					<span className='text-xs lg:text-sm'>Days Missed</span>
				</div>
				<div className='flex flex-col justify-center items-center basis-2/12'>
					<span className='text-red-500 font-semibold text-xs lg:text-sm'>
						{props.medication_occurrence_missed}
					</span>
					<span className='text-xs lg:text-sm'>Medicines Missed</span>
				</div>
			</div>
			<div className='h-[2px] w-full bg-slate-300'></div>
		</>
	);
};

export default MedicineCard;
