import React, { useState } from "react";
import OtpField from "../components/login/OtpField";
import Email from "../components/login/Email";
const Login = ({ setSigned }) => {
	document.title = `SmitchCare | Login`;
	const [emailEntered, setEmailEntered] = useState(false);
	const [email, setEmail] = useState("");
	
	return (
		<>
			{!emailEntered && (
				<Email
					setEmailEntered={setEmailEntered}
					setEmail={setEmail}
					email={email}
					emailEntered={emailEntered}
				/>
			)}
			{emailEntered && <OtpField email={email} setSigned={setSigned} />}
		</>
	);
};

export default React.memo(Login);
