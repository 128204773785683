import React, { useState } from "react";
import { Otpsent } from "../../utils/apis";
import Toast from "../Toast";
import { toast } from "react-hot-toast";
const Email = ({ setEmail, setEmailEntered, email }) => {
	const [value, setValue] = useState("");
	const [sendingOtp, setSendingOtp] = useState(false);
	const validateEmail = (inputText) => {
		const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
		if (inputText.match(mailformat)) {
			return true;
		} else {
			return false;
		}
	};

	const handleChange = (e) => {
		setEmail(e.target.value);
		setValue(e.target.value);
	};

	const handleSubmit = async () => {
		if (sendingOtp) {
			return;
		}
		if (validateEmail(email)) {
			setSendingOtp(true);
			const res = await Otpsent(email);
			if (res.status === "success") {
				setEmailEntered(true);
			} else {
				toast.error(res.response.data.message);
			}
		} else {
			toast.error("Please enter a valid Email");
		}
		setSendingOtp(false);
	};
	const handleSubmitEnter = (event) => {
		if (event.key === "Enter") {
			handleSubmit();
		}
	};

	return (
		<div className='relative flex min-h-screen font-smitchSans  flex-col justify-center overflow-hidden bg-gray-50 py-12'>
			<div className='relative bg-white px-6 border pt-10 pb-9 shadow-inner mx-auto w-full max-w-lg rounded-2xl'>
				<div className='mx-auto flex w-full max-w-md flex-col space-y-16'>
					<div className='flex flex-col items-center justify-center text-center space-y-2'>
						<div className='font-semibold flex gap-5 text-2xl'>
							<img
								className='w-9'
								src='https://web.mysmitch.com/static/media/SMITCH.3427e991.png'
								alt=''
							/>
							<p>Welcome to SmitchCare</p>
						</div>
					</div>

					<div>
						<div className='flex flex-col space-y-16'>
							<div className='mb-3 pt-0'>
								<input
									onChange={(e) => {
										handleChange(e);
									}}
									onKeyDown={handleSubmitEnter}
									value={value}
									type='text'
									placeholder='Please Enter Your Email Here'
									className='px-3 py-3 placeholder-slate-300 text-slate-600 relative bg-white rounded text-sm border border-slate-300 outline-none focus:outline-none focus:ring w-full'
								/>
							</div>
							<div className='flex flex-col space-y-5'>
								<div>
									<button
										onClick={handleSubmit}
										className='flex flex-row items-center justify-center text-center w-full border rounded-xl outline-none py-3 bg-blue-700 border-none text-white text-sm shadow-sm'>
										Next
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Toast />
		</div>
	);
};

export default Email;
