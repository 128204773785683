import React, { useEffect, useState, useContext } from "react";
import Datepicker from "react-tailwindcss-datepicker";
import { getData } from "../utils/apis";
import { ParamsContext } from "../contexts/ParamsContext";
import { UserContext } from "../contexts/UserContext";
import Cookies from "universal-cookie";
const SelectTimeFrame = ({ dateSet, setDateSet }) => {
	const [count, setCount] = useState(0);
	const availableParams = useContext(ParamsContext);
	const { date, setDate } = useContext(ParamsContext);
	const cookies = new Cookies();
	const access_token = cookies.get("access_token");
	const { userData } = useContext(UserContext);
	const { client_id, user_id } = userData;
	function formatDate(date) {
		let d = new Date(date),
			month = "" + (d.getMonth() + 1),
			day = "" + d.getDate(),
			year = d.getFullYear();

		if (month.length < 2) month = "0" + month;
		if (day.length < 2) day = "0" + day;

		return {
			startDate: [year - 1, month, day].join("-"),
			endDate: [year, month, day].join("-"),
		};
	}

	let dates = formatDate(new Date(Date.now()));
	useEffect(() => {
		if (dateSet) {
			(async () => {
				let end_time = new Date(date.endDate);
				end_time.setHours(end_time.getHours() + 23);
				end_time.setMinutes(end_time.getMinutes() + 59);
				end_time.setSeconds(end_time.getSeconds() + 59);
				const fetched_params = await getData(
					{
						client_id,
						access_token,
						"content-Type": "application/json",
						user_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
					},
					{
						params: [],
						user_id,
						start_time: new Date(date.startDate).toISOString(),
						end_time: new Date(end_time).toISOString(),
					},
					"getAvaliableReportParams",
				);
				fetched_params.status === "success" &&
					availableParams.setParams(
						Object.keys(fetched_params?.data?.categories),
					);
			})();
		}
	}, [dateSet, count]);
	const handleValueChange = async (newValue) => {
		setDate(newValue);
		// setShow(false);
		setDateSet(true);
		setCount(count + 1);
	};

	return (
		<div className='mt-4 cursor-pointer w-full'>
			<Datepicker
				configs={{
					shortcuts: {
						yesterday: "Yesterday",
						currentMonth: "This Month",
						pastMonth: "Last Month",
						past: (period) => `Last ${period} Days`,
						lastYear: {
							text: "Last 365 Days",
							period: {
								start: dates.startDate,
								end: dates.endDate,
							},
						},
					},
				}}
				useRange={false}
				primaryColor={"blue"}
				value={date}
				onChange={handleValueChange}
				showShortcuts={true}
				displayFormat={"DD MMM YYYY"}
				maxDate={new Date()}
				containerClassName=''
			/>
		</div>
	);
};
export default SelectTimeFrame;
