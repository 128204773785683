import React, { useRef, useState } from "react";
import CanvasJSReact from "../../assets/canvasjs/canvasjs.react";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import { handleScrollLeft, handleScrollRight } from "../../utils/helper";

const CanvasJSChart = CanvasJSReact.CanvasJSChart;
const CaloriesConsumed = ({ dataPoints, average }) => {
	const [scrollPosition, setScrollPosition] = useState(0);
	const scrollableDiv = useRef(null);
	const handleScroll = () => {
		if (scrollableDiv.current) {
			setScrollPosition(scrollableDiv.current.scrollLeft);
		}
	};

	const calorieRef = useRef(null);
	const color = "#0A84FF";
	const options = {
		dataPointWidth: 5,
		marginBottom: 10,
		willReadFrequently: true,
		height: 135,
		animationEnabled: true,
		animationDuration: 1200,
		labelPlacement: "outside",
		axisY: {
			minimum: 0,
			gridColor: "white",
			title: "",
			includeZero: true,
			tickLength: 0,
			labelFontColor: "gray",
			indexLabelWrap: 10,

			lineThickness: 0,

			stripLines: [
				{
					thickness: 1,
					color: "grey",
					value: +average,
					label: +average + " avg",
					labelPlacement: "inside",
					labelAlign: "far",
					lineDashType: "longDash",
					labelWidth: 50,
					labelFontColor: "black",
					labelFontSize: 10,
				},
			],
		},
		axisX: {
			title: "",
			tickLength: 0,
			margin: 0,
			valueFormatString: " ",
			lineThickness: 0,
		},
		data: [
			{
				type: "column",
				color,
				radius: 4,
				dataPoints,
			},
		],
	};
	if (dataPoints.length > 30) {
		options.width = dataPoints.length * 40;
	}
	return (
		<div>
			<h2 className='text-md font-semibold'>Calories Consumed</h2>
			<div
				ref={scrollableDiv}
				onScroll={handleScroll}
				className={
					dataPoints.length > 30
						? `w-full overflow-x-scroll scroll-smooth [&::-webkit-scrollbar]:hidden [-ms-overflow-style:'none'] [scrollbar-width:'none']`
						: `w-full`
				}>
				<CanvasJSChart
					options={options}
					ref={calorieRef}
					/* onRef={ref => this.chart = ref} */
				/>
			</div>
			<div className='flex w-full relative justify-between top-[-100px]'>
				<div>
					{scrollPosition !== 0 && (
						<BsArrowLeft
							onClick={() => {
								handleScrollLeft(
									scrollableDiv,
									setScrollPosition,
									scrollPosition,
								);
							}}
							size='25px'
							color='rgb(59 130 246)'
							className='cursor-pointer'
							disabled={scrollPosition === 0}
						/>
					)}
				</div>
				<div>
					{scrollPosition !== scrollableDiv?.current?.scrollWidth && (
						<BsArrowRight
							onClick={() => {
								handleScrollRight(
									scrollableDiv,
									setScrollPosition,
									scrollPosition,
								);
							}}
							color='rgb(59 130 246)'
							size='25px'
							className='cursor-pointer'
							disabled={
								scrollPosition ===
								scrollableDiv?.current?.scrollWidth -
									scrollableDiv?.current?.clientWidth
							}
						/>
					)}
				</div>
			</div>
		</div>
	);
};
export default CaloriesConsumed;
