import React, { useState, useEffect, useContext } from "react";
import sleep_icon from "../../assets/icons/Style6.png";
import SleepStages from "./SleepStages";
import SleepRegularity from "./SleepRegularity";
import { getData } from "../../utils/apis";
import Loading from "../Loading.jsx";
import { UserContext } from "../../contexts/UserContext.js";
import Cookies from "universal-cookie";
import { ParamsContext } from "../../contexts/ParamsContext";
const SleepReport = () => {
	const [data, setData] = useState({});
	const [loading, setLoading] = useState(true);
	const { userData } = useContext(UserContext);
	const { client_id, user_id } = userData;
	const { date } = useContext(ParamsContext);
	const cookies = new Cookies();
	const access_token = cookies.get("access_token");
	
	useEffect(() => {
		(async () => {
			let end_time = new Date(date.endDate);
			end_time.setUTCHours(18, 29, 59, 999);
			const fetched_data = await getData(
				{
					client_id,
					access_token,
					"content-Type": "application/json",
					user_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
				},
				{
					params: ["sleep"],
					user_id,
					start_time: new Date(date.startDate).toISOString(),
					end_time: end_time.toISOString(),
				},
				"getSleep",
			);
			setData(fetched_data);
			setLoading(false);
		})();
		const handlePopState = () => {
			// This function will be called when the user navigates back
			setLoading(true); // Set loading to true when navigating back
		  };
	  
		  window.addEventListener('popstate', handlePopState);
	  
		  return () => {
			window.removeEventListener('popstate', handlePopState);
		  };
	}, []);

	return loading ? (
		<Loading />
	) : data.status === "success" ? (
		<div className='flex font-smitchSans flex-col sm:w-[60%] w-[90%] m-auto mt-5 gap-10 '>
			<div className='flex items-center  gap-5'>
				<div className='shadow-inner border-2 flex  rounded-full w-[60px] h-[60px] items-center justify-center '>
					<img src={sleep_icon} className='w-[30px]' alt='' />
				</div>
				<h2 className='text-xl font-bold'>Sleep</h2>
			</div>
			{/* sleepStatges and Sleep regularity charts rendered     ////////// */}
			{data?.data?.showSleepStagesGraph && <SleepStages data={data?.data} />}
			{data?.data?.regularityDataPoints && <SleepRegularity
				dataPoints={data?.data?.regularityDataPoints}
				avgRiseTime={data.data.avgRiseTime}
				avgBedTime={data.data.avgBedTime}
			/>}
			{/* sleep tables ///////////// */}
			<div className='flex flex-col sm:flex-row gap-5 sm:gap-5 justify-around '>
				<div className='flex flex-col w-[300px] '>
					<div className='flex justify-between'>
						<span className='text-sm font-light'>Avg. Sleep Duration</span>
						<span>
							{data.data.avgSleep.sleepHour
								? `${data.data.avgSleep.sleepHour} hr`
								: "    "}{" "}
							{data.data.avgSleep.sleepMin} mins
						</span>
					</div>
					<div className='h-[1px] w-[260px] bg-slate-300'></div>
					<div className='flex justify-between'>
						<span className='text-sm font-light'>
							Avg.Sleep during weekdays
						</span>
						<span>
							{data.data.avgSleep.weekDaysDurationHour
								? `${data.data.avgSleep.weekDaysDurationHour} hr`
								: "    "}{" "}
							{data.data.avgSleep.weekDaysDurationMin} mins
						</span>
					</div>
					<div className='h-[1px] w-[260px] bg-slate-300'></div>
					<div className='flex justify-between '>
						<span className='text-sm font-light'>
							Avg.Sleep during Weekends
						</span>
						<span>
							{data.data.avgSleep.WeekendDayHour
								? `${data.data.avgSleep.WeekendDayHour} hr`
								: "    "}{" "}
							{data.data.avgSleep.WeekendDayMin} mins
						</span>
					</div>
				</div>
				<div className='flex flex-col justify-between'>
					<span>Avg. Time in sleep Stage</span>
					<div className='w-[150px] flex justify-between text-sm font-light'>
						<span>Awake</span>
						<span>{data.data.avgSleep.awake} mins</span>
					</div>
					<div className='w-[150px] flex justify-between text-sm font-light'>
						<span>Light</span>
						<span>{data.data.avgSleep.light} mins</span>
					</div>
					<div className='w-[150px] flex justify-between text-sm font-light'>
						<span>REM</span>
						<span>{data.data.avgSleep.rem} mins</span>
					</div>
					<div className='w-[150px] flex justify-between text-sm font-light'>
						<span>Deep</span>
						<span>{data.data.avgSleep.deep} mins</span>
					</div>
				</div>
			</div>
			<div className='flex items-center gap-10 '>
				<h4>Average Sleep Score</h4>
				<div className='rounded-full ring-[5px] ring-yellow-400 ring-inset w-[51px] h-[51px] flex justify-center items-center'>
					<span className='font-bold text-2xl text-yellow-400'>
						{data.data.avgSleep.score}
					</span>
				</div>
			</div>
		</div>
	) : (
		data.status === "false" && (
			<div className='text-xl sm:w-[60%] w-[90%] m-auto'>
				Not able to get Sleep Report Data
			</div>
		)
	);
};

export default SleepReport;
