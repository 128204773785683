import React from "react";

import VitalTableCard from "./VitalTableCard";
const VitalsTable = ({ title,...props }) => {


  return (
    <div>
      <div className="flex flex-col gap-2">
        <h1 className="text-lg font-bold mb-3">{title}</h1>
        <div className="h-[2px] w-full bg-slate-300"></div>
        {Object.values(props).map((prop, index) => {
          return <VitalTableCard key={index} {...prop} />;
        })}
      </div>
    </div>
  );
};

export default VitalsTable;
