const handleScrollLeft = (scrollableDiv, setScrollPosition, scrollPosition) => {
	if (scrollableDiv.current) {
		const clientWidth = scrollableDiv.current.clientWidth;
		const newPosition = scrollPosition - clientWidth;
		if (newPosition < 0) {
			setScrollPosition(0);
			scrollableDiv.current.scrollLeft = 0;
		} else {
			setScrollPosition(newPosition);
			scrollableDiv.current.scrollLeft = newPosition;
		}
	}
};

const handleScrollRight = (
	scrollableDiv,
	setScrollPosition,
	scrollPosition,
) => {
	if (scrollableDiv.current) {
		const scrollWidth = scrollableDiv.current.scrollWidth;
		const clientWidth = scrollableDiv.current.clientWidth;
		const newPosition = scrollPosition + clientWidth;
		if (newPosition > scrollWidth - clientWidth) {
			setScrollPosition(scrollWidth - clientWidth);
			scrollableDiv.current.scrollLeft = scrollWidth - clientWidth;
		} else {
			setScrollPosition(newPosition);
			scrollableDiv.current.scrollLeft = newPosition;
		}
	}
};

const updateQueryParams = (params) => {
	const newParams = new URLSearchParams(window.location.search);
	Object.entries(params).forEach(([key, value]) => {
		newParams.set(key, value);
	});

	window.history.replaceState(null, "", `?${newParams.toString()}`);
};

const getQueryParams = (param) => {
	const newParams = new URLSearchParams(window.location.search);
	return newParams.get(param);
};

export {
	handleScrollLeft,
	handleScrollRight,
	updateQueryParams,
	getQueryParams,
};
