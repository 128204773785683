import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Logout from "../components/Logout";
import { UserContext } from "../contexts/UserContext";
import { updateQueryParams } from "../utils/helper";
const Navigation = ({ setSigned, setMountLogin }) => {
	document.title = `SmitchCare`;
	const navigate = useNavigate();
	let { userData } = useContext(UserContext);
	useEffect(() => {
		updateQueryParams({
			user_id: userData.user_id,
		});
	}, [userData.user_id]);
	const handleSubmitEcgReport = () => {
		navigate("/allecg");
	};
	const handleSubmitOtherReports = () => {
		navigate("/generate");
	};

	return (
		<div className='relative flex font-smitchSans min-h-screen flex-col justify-center overflow-hidden bg-gray-50 py-12'>
			<div className='relative bg-white border px-6 pt-10 pb-9 shadow-inner mx-auto w-full max-w-lg rounded-2xl'>
				<div className='mx-auto flex w-full max-w-md flex-col space-y-16'>
					<button
						className='flex flex-row items-center justify-center text-center w-full border rounded-xl outline-none py-3 bg-blue-700 border-none text-white text-sm shadow-sm'
						onClick={handleSubmitOtherReports}>
						Click me to view other reports
					</button>
					<button
						className='flex flex-row items-center justify-center text-center w-full border rounded-xl outline-none py-3 bg-blue-700 border-none text-white text-sm shadow-sm'
						onClick={handleSubmitEcgReport}>
						Click me to view ECG report
					</button>
				</div>
			</div>
			<Logout setSigned={setSigned} setMountLogin={setMountLogin} />
		</div>
	);
};

export default Navigation;
