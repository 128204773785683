import React, { useEffect, useContext } from "react";
import Datepicker from "react-tailwindcss-datepicker";
import { ParamsContext } from "../contexts/ParamsContext";
import { getData } from "../utils/apis";
import { UserContext } from "../contexts/UserContext";
import Cookies from "universal-cookie";
import Toast from './Toast'
import {toast} from  "react-hot-toast";
const SelectEcgTimeFrame = ({
	setEcgDateSet,
	ecgDateSet,
	setLoading,
	setAllEcg,
}) => {
	const { ecgDate, setEcgDate } = useContext(ParamsContext);
	function formatDate(date) {
		let d = new Date(date),
			month = "" + (d.getMonth() + 1),
			day = "" + d.getDate(),
			year = d.getFullYear();
		if (month.length < 2) month = "0" + month;
		if (day.length < 2) day = "0" + day;
		return {
			startDate: [year - 1, month, day].join("-"),
			endDate: [year, month, day].join("-"),
		};
	}
	let dates = formatDate(new Date(Date.now()));
	const { userData } = useContext(UserContext);
	const { client_id, user_id } = userData;
	const handleValueChange = async (newValue) => {
		setEcgDate(newValue);

		setEcgDateSet(true);
	};
	const cookies = new Cookies();
	const access_token = cookies.get("access_token");
	useEffect(() => {
		if (ecgDateSet) {
			(async () => {
				try {
					let end_time = new Date(ecgDate.endDate);
					end_time.setHours(end_time.getHours() + 23);
					end_time.setMinutes(end_time.getMinutes() + 59);
					end_time.setSeconds(end_time.getSeconds() + 59);

					const fetched_data = await getData(
						{
							client_id,
							access_token,
							user_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
						},

						{
							params: [],
							user_id,
							start_time: new Date(ecgDate.startDate).toISOString(),
							end_time: end_time.toISOString(),
						},
						"getEcg",
					);
					if (fetched_data.name === "AxiosError") {
						toast.error(fetched_data.response.data.message);
					}
					// if(fetched)
					setAllEcg(fetched_data);
					setLoading(false);
				} catch (error) {
					return error.message;
				}
			})();
		}
	}, [ecgDateSet, ecgDate.startDate, ecgDate.endDate]);

	return (
		<div className='mt-4 w-full scr'>
			<Datepicker
				configs={{
					shortcuts: {
						yesterday: "Yesterday",
						currentMonth: "This Month",
						pastMonth: "Last Month",
						past: (period) => `Last ${period} Days`,
						lastYear: {
							text: "Last 365 Days",
							period: {
								start: dates.startDate,
								end: dates.endDate,
							},
						},
					},
				}}
				useRange={false}
				primaryColor={"blue"}
				value={ecgDate}
				onChange={handleValueChange}
				showShortcuts={true}
				maxDate={new Date()}
				displayFormat={"DD MMM YYYY"}
				containerClassName=''
			/>
			<Toast/>
		</div>
	);
};
export default SelectEcgTimeFrame;
