import React from "react";
import mental_icon from "../../assets/icons/mental_icon.png";
const MentalHealthReport = () => {
	return (
		<div className='flex flex-col sm:w-[60%] w-[90%] m-auto mt-5 gap-10 '>
			<div className='flex items-center  gap-5'>
				<div className='shadow-inner flex  rounded-full w-[60px] h-[60px] items-center justify-center '>
					<img src={mental_icon} className='w-[30px] rounded-full' alt='' />
				</div>
				<h2 className='text-xl font-bold'>Mental Health</h2>
			</div>
		</div>
	);
};

export default MentalHealthReport;
