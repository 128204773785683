/* App.js */
import React, { useRef, useState } from "react";
import { handleScrollLeft, handleScrollRight } from "../../utils/helper";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import CanvasJSReact from "../../assets/canvasjs/canvasjs.react";

const CanvasJSChart = CanvasJSReact.CanvasJSChart;
const SleepRegularity = ({ dataPoints, avgBedTime, avgRiseTime }) => {
	const sleepregularref = useRef(null);
	const [scrollPosition, setScrollPosition] = useState(0);
	const scrollableDiv = useRef(null);
	const handleScroll = () => {
		if (scrollableDiv.current) {
			setScrollPosition(scrollableDiv.current.scrollLeft);
		}
	};

	const options = {
		dataPointWidth: 5,
		marginBottom: 10,
		willReadFrequently: true,
		height: 135,
		animationEnabled: true,
		animationDuration: 1200,
		axisX: {
			minimum: 0,
			title: "",
			tickLength: 0,
			margin: 0,
			valueFormatString: " ",
			lineThickness: 0,
			labelFormatter: function (e) {
				if (e.label) {
					const splitDateArray = e.label.split("-");
					if (splitDateArray.length > 0) {
						return `${splitDateArray[2]}/${splitDateArray[1]}`;
					}
				}
				return " ";
			},
			gridThickness: 0,
			labelFontColor: "#9F9F9F",
			tickColor: "#9F9F9F",
			lineColor: "#9F9F9F",
		},
		axisY: {
			minimum: 0,
			gridColor: "white",
			title: "",
			includeZero: true,
			tickLength: 0,
			labelFontColor: "gray",

			lineThickness: 0,
			stripLines: [
				{
					lineDashType: "longDash",
					thickness: 1,
					value: avgBedTime.bedHr,
					label: avgRiseTime.riseTime
						? `Avg. Bedtime ${avgBedTime.bedTime}`
						: "",
					color: "#9F9F9F",
					labelFontColor: "black",
					labelFontSize: 10,
					labelWrap: true,
					showOnTop: true,
					labelAlign: "far",
				},
				{
					lineDashType: "longDash",
					thickness: 1,
					value: avgRiseTime.riseHr,
					label: avgRiseTime.riseTime
						? `Avg. Waketime ${avgRiseTime.riseTime}`
						: "",
					color: "#9F9F9F",
					labelFontColor: "black",
					labelFontSize: 10,
					labelWrap: true,
					showOnTop: true,
					labelAlign: "far",
				},
			],
			gridThickness: 0,
		},
		dataPointMaxWidth: 5,
		data: [
			{
				type: "rangeColumn",
				color: "#53D769",
				dataPoints,
			},
		],
	};
	if (dataPoints.length > 30) {
		options.width = dataPoints.length * 40;
	}

	return (
		<div>
			<h2 className='text-md font-semibold'>Sleep Regularity</h2>
			<div
				ref={scrollableDiv}
				onScroll={handleScroll}
				className={
					dataPoints?.length > 30
						? `w-full overflow-x-scroll scroll-smooth [&::-webkit-scrollbar]:hidden [-ms-overflow-style:'none'] [scrollbar-width:'none']`
						: `w-full`
				}>
				<CanvasJSChart options={options} ref={sleepregularref} />
			</div>
			<div className='flex w-full relative justify-between top-[-100px]'>
				<div>
					{scrollPosition !== 0 && (
						<BsArrowLeft
							onClick={() => {
								handleScrollLeft(
									scrollableDiv,
									setScrollPosition,
									scrollPosition,
								);
							}}
							size='25px'
							color='rgb(59 130 246)'
							className='cursor-pointer'
							disabled={scrollPosition === 0}
						/>
					)}
				</div>
				<div>
					{scrollPosition !== scrollableDiv?.current?.scrollWidth && (
						<BsArrowRight
							onClick={() => {
								handleScrollRight(
									scrollableDiv,
									setScrollPosition,
									scrollPosition,
								);
							}}
							color='rgb(59 130 246)'
							size='25px'
							className='cursor-pointer'
							disabled={
								scrollPosition ===
								scrollableDiv?.current?.scrollWidth -
									scrollableDiv?.current?.clientWidth
							}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default SleepRegularity;
