import axios from "axios";

const getData = async (headers, data, end_point) => {
	const { REACT_APP_BASE_URL } = process.env;
	const result = await axios
		.post(`${REACT_APP_BASE_URL}/user/reports/${end_point}`, data, { headers })
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
	return result;
};

const Otpsent = async (email) => {
	const { REACT_APP_TELEMED_BASE_URL, REACT_APP_CLIENT_ID } = process.env;
	const result = await axios
		.post(
			`${REACT_APP_TELEMED_BASE_URL}/tele-med/providers/email/otp`,
			{ email },
			{
				headers: {
					"content-Type": "application/json",
					client_id: REACT_APP_CLIENT_ID,
				},
			},
		)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
	return result;
};
const matchOtp = async (otp, email) => {
	const { REACT_APP_TELEMED_BASE_URL, REACT_APP_CLIENT_ID } = process.env;
	const result = await axios
		.post(
			`${REACT_APP_TELEMED_BASE_URL}/tele-med/providers/email/login`,
			{ email, otp },
			{
				headers: {
					"content-Type": "application/json",
					client_id: REACT_APP_CLIENT_ID,
				},
			},
		)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
	return result;
};

export { getData, Otpsent, matchOtp };
