//Hr zones chart
import React, { useRef, useState } from "react";
import CanvasJSReact from "../../assets/canvasjs/canvasjs.react";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import { handleScrollLeft, handleScrollRight } from "../../utils/helper";
let CanvasJSChart = CanvasJSReact.CanvasJSChart;

const HrZones = ({ data }) => {
	const hrRef = useRef(null);
	const [scrollPosition, setScrollPosition] = useState(0);
	const scrollableDiv = useRef(null);

	const handleScroll = () => {
		if (scrollableDiv.current) {
			setScrollPosition(scrollableDiv.current.scrollLeft);
		}
	};

	let sum = 0;
	let [arr1, arr2, arr3, arr4] = [
		data.lightHrZonesDataPoints,
		data.moderateHrZonesDataPoints,
		data.intenseHrZonesDataPoints,
		data.peakHrZonesDataPoints,
	];
	let l1 = 0,
		l2 = 0,
		l3 = 0,
		l4 = 0;

	for (let i = 0; i < arr1.length; i++) {
		if (arr1[i].y) {
			l1++;
			sum += arr1[i].y;
		}
	}
	for (let i = 0; i < arr2.length; i++) {
		if (arr2[i].y) {
			l2++;
			sum += arr2[i].y;
		}
	}
	for (let i = 0; i < arr3.length; i++) {
		if (arr3[i].y) {
			l3++;
			sum += arr3[i].y;
		}
	}
	for (let i = 0; i < arr4.length; i++) {
		if (arr4[i].y) {
			l4++;
			sum += arr4[i].y;
		}
	}
	let average = sum / (l1 + l2 + l3 + l4);

	const options = {
		dataPointWidth: 5,
		marginBottom: 10,
		height: 135,
		animationEnabled: true,
		willReadFrequently: true,
		animationDuration: 1200,
		legend: {
			itemWidth: "120",
		},
		axisY: {
			minimum: 0,
			gridColor: "white",
			title: "",
			includeZero: true,
			tickLength: 0,
			labelFontColor: "gray",
			lineThickness: 0,

			stripLines: [
				{
					thickness: 1,
					color: "gray",
					value: average,
					label: average.toPrecision(3) + " bpm avg",
					lineDashType: "longDash",
					labelPlacement: "inside",
					labelAlign: "far",
					labelWidth: 50,
					labelFontColor: "black",
					labelFontSize: 10,
				},
			],
		},
		axisX: {
			title: "",
			tickLength: 0,
			margin: 0,
			valueFormatString: " ",
			lineThickness: 0,
		},

		data: [
			{
				type: "stackedColumn",
				name: "Light",
				color: "#FECB2E",
				showInLegend: "true",
				dataPoints: data.lightHrZonesDataPoints,
			},
			{
				type: "stackedColumn",
				name: "Moderate",
				color: "#F2994A",
				showInLegend: "true",
				dataPoints: data.moderateHrZonesDataPoints,
			},
			{
				type: "stackedColumn",
				name: "Intense",
				color: "#FF6229",
				showInLegend: "true",
				dataPoints: data.intenseHrZonesDataPoints,
			},
			{
				type: "stackedColumn",
				name: "Peak",
				color: "#FC3D39",
				showInLegend: "true",
				dataPoints: data.peakHrZonesDataPoints,
			},
		],
	};
	if (data.lightHrZonesDataPoints.length>30 ||
		data.moderateHrZonesDataPoints.length>30||
		data.intenseHrZonesDataPoints.length>30 ||
		data.peakHrZonesDataPoints.length>30) {
		options.width = Math.max(arr1.length,arr2.length,arr3.length,arr4.length) * 40;
	}

	return (
		<div data-testid='hr-zones-chart'>
			<p className='text-md font-semibold'>Hr Zones</p>
			<div
				ref={scrollableDiv}
				onScroll={handleScroll}
				className={
					Math.max(arr1.length,arr2.length,arr3.length,arr4.length) > 30
						? `w-full overflow-x-scroll scroll-smooth [&::-webkit-scrollbar]:hidden [-ms-overflow-style:'none'] [scrollbar-width:'none']`
						: `w-full`
				}>
				<CanvasJSChart options={options} ref={hrRef} />
			</div>
			<div className='flex w-full relative justify-between top-[-100px]'>
				<div>
					{scrollPosition !== 0 && (
						<BsArrowLeft
							onClick={() => {
								handleScrollLeft(
									scrollableDiv,
									setScrollPosition,
									scrollPosition,
								);
							}}
							size='25px'
							color='rgb(59 130 246)'
							className='cursor-pointer'
							disabled={scrollPosition === 0}
						/>
					)}
				</div>
				<div>
					{scrollPosition !== scrollableDiv?.current?.scrollWidth && (
						<BsArrowRight
							onClick={() => {
								handleScrollRight(
									scrollableDiv,
									setScrollPosition,
									scrollPosition,
								);
							}}
							color='rgb(59 130 246)'
							size='25px'
							className='cursor-pointer'
							disabled={
								scrollPosition ===
								scrollableDiv?.current?.scrollWidth -
									scrollableDiv?.current?.clientWidth
							}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default HrZones;
