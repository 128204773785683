import React, { useState, useContext, useEffect } from "react";
import Button from "../components/Button";
import SelectTimeFrame from "../components/SelectTimeFrame";
import MultiSelectOption from "../components/MultiSelect.jsx";
import { ParamsContext } from "../contexts/ParamsContext";
import { useNavigate } from "react-router-dom";
import Logout from "../components/Logout";
import { updateQueryParams } from "../utils/helper";
import { UserContext } from "../contexts/UserContext";
const GenerateReport = ({ setSigned, setMountLogin }) => {
	document.title = `SmitchCare | Generate Report`;
	const navigate = useNavigate();
	const paramsAvailable = useContext(ParamsContext).params;
	const { selected, date } = useContext(ParamsContext);
	const [dateSet, setDateSet] = useState(false);
	const { userData } = useContext(UserContext);
	useEffect(() => {
		let TobeDisplayed = [];
		for (let i = 0; i < selected.length; i++) {
			TobeDisplayed.push(selected[i].value);
		}
		updateQueryParams({
			user_id: userData.user_id,
			selected: TobeDisplayed.join(" "),
			startDate: new Date(date.startDate).toISOString(),
			endDate: new Date(date.endDate).toISOString(),
		});
	}, [userData.user_id, selected.length, date.startDate, date.endDate]);

	const handleSubmit = () => {
		navigate("/reports");
	};

	return (
		<div className='flex font-smitchSans  flex-col w-[400px] border m-auto mt-[100px] gap-10 bg-white px-6 shadow-inner mx-auto max-w-lg rounded-2xl'>
			<h1 className='text-[30px] font-bold self-center mt-5'>
				Generate Report
			</h1>
			<h4 className='ml-3'>Select a time frame</h4>
			<div className='flex flex-col justify-center flex-1  '>
				<div className='flex flex-col flex-1 w-full gap-5 m-auto items-center'>
					<SelectTimeFrame dateSet={dateSet} setDateSet={setDateSet} />
				</div>

				{dateSet ? (
					paramsAvailable.length > 0 ? (
						<MultiSelectOption params_available={paramsAvailable} />
					) : (
						<div className='text-lg'>No Available Params</div>
					)
				) : (
					<></>
				)}
				<Button onClickFn={handleSubmit} />
			</div>
			<Logout setSigned={setSigned} setMountLogin={setMountLogin} />
		</div>
	);
};

export default GenerateReport;
