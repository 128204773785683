import React, { useContext, useEffect } from "react";
import { ParamsContext } from "../contexts/ParamsContext";
import ActivityReport from "../components/activity/ActivityReport";
import UserDetails from "../components/allStats/UserDetails";
import SleepReport from "../components/sleep/SleepReport";
import NutritionReport from "../components/nutrition/NutritionReport";
import VitalsReport from "../components/vitals/VitalsReport";
import MedicinesReport from "../components/medicines/MedicinesReport";
import MentalHealthReport from "../components/mentalHealth/MentalHealthReport";
import BodyCompositionReport from "../components/bodyComposition/BodyCompositionReport";
import Logout from "../components/Logout";
import { updateQueryParams } from "../utils/helper";
import { UserContext } from "../contexts/UserContext";

const AllReports = ({ setSigned, setMountLogin }) => {
	document.title = `SmitchCare | Reports`;
	let { selected, date } = useContext(ParamsContext);
	let { userData } = useContext(UserContext);

	let TobeDisplayed = [];
	for (let i = 0; i < selected.length; i++) {
		TobeDisplayed.push(selected[i].value);
	}
	useEffect(() => {
		updateQueryParams({
			user_id: userData.user_id,
			selected: TobeDisplayed.join(" "),
			startDate: new Date(date.startDate).toISOString(),
			endDate: new Date(date.endDate).toISOString(),
		});
	}, [userData.user_id, selected.length]);
	return (
		<div>
			<UserDetails />
			{TobeDisplayed.includes("Activity") && <ActivityReport />}
			{TobeDisplayed.includes("Sleep") && <SleepReport />}
			{TobeDisplayed.includes("Nutrition") && <NutritionReport />}
			{TobeDisplayed.includes("Blood") && <VitalsReport />}
			{TobeDisplayed.includes("Medicine") && <MedicinesReport />}
			{TobeDisplayed.includes("mentalHealthReport") && <MentalHealthReport />}
			{TobeDisplayed.includes("Body_Measurement") && <BodyCompositionReport />}
			<Logout setSigned={setSigned} setMountLogin={setMountLogin} />
		</div>
	);
};

export default AllReports;
