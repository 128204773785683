import React from "react";

const EcgModal = ({ setShowModal, showModal, Chart1, Chart2, Chart3 }) => {
	return (
		<div>
			{showModal ? (
				<div className=' flex flex-col gap-6  justify-center bg-white items-center h-[100vh] overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
					<div className='mt-3'>
						<button
							className='bg-transparent border-0 text-black float-right'
							onClick={() => setShowModal(false)}>
							<span className='text-white p-5  animate-bounce bg-blue-600 hover:bg-blue-800 cursor-pointer z-10 lg:top-2 lg:right-20 top-2 right-0 opacity-7 h-8 text-xl block  py-0 rounded-full'>
								Close
							</span>
						</button>
					</div>
					<div className='w-screen h-screen '>
						<Chart1 />
						<Chart2 />
						<Chart3 />
					</div>
				</div>
			) : null}
		</div>
	);
};

export default EcgModal;
