import React, { useState, useEffect, useContext } from "react";
import medicine_icon from "../../assets/icons/medicine_icon.png";
import { UserContext } from "../../contexts/UserContext.js";
import { ParamsContext } from "../../contexts/ParamsContext";
import { getData } from "../../utils/apis";
import Loading from "../Loading";
import MedicineCard from "./MedicineCard";
import Cookies from "universal-cookie";
const MedicinesReport = () => {
	const [data, setData] = useState({});
	const [loading, setLoading] = useState(true);
	const { userData } = useContext(UserContext);
	const { client_id, user_id } = userData;
	const { date } = useContext(ParamsContext);
	const cookies = new Cookies();
	const access_token = cookies.get("access_token");
	useEffect(() => {
		(async () => {
			let end_time = new Date(date.endDate);
			end_time.setUTCHours(18, 29, 59, 999);
			const fetched_data = await getData(
				{
					client_id,
					access_token,
					"content-Type": "application/json",
					user_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
				},
				{
					params: [],
					user_id,
					start_time: new Date(date.startDate).toISOString(),
					end_time: end_time.toISOString(),
				},
				"getMedicine",
			);
			setData(fetched_data);
			setLoading(false);
		})();
	}, []);
	return loading ? (
		<Loading />
	) : data.status === "success" && data.data.length > 0 ? (
		<div className='flex flex-col font-smitchSans sm:w-[60%] w-[90%] m-auto mt-5 gap-10 '>
			<div className='flex items-center gap-5'>
				<div className='shadow-inner border-2 flex  rounded-full w-[60px] h-[60px] items-center justify-center '>
					<img src={medicine_icon} className='w-[30px] ' alt='' />
				</div>
				<h2 className='text-xl font-bold'>Medicines</h2>
			</div>
			{/* all medicines are mapped using medicine card component */}
			{data.data.map((med, index) => {
				return <MedicineCard key={index} props={med} />;
			})}
		</div>
	) : (
		data.status === "failed" && (
			<div className='text-xl sm:w-[60%] w-[90%] m-auto'>
				Not able to get Medicine Report
			</div>
		)
	);
};

export default MedicinesReport;
