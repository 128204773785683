import React, { useState, useEffect, useContext } from "react";
import bodyComposition_icon from "../../assets/icons/bodyComposition_icon.png";
import { getData } from "../../utils/apis";
import Loading from "../Loading";
import BodyFatPercentage from "./BodyFatPercentage";
import { UserContext } from "../../contexts/UserContext.js";
import { ParamsContext } from "../../contexts/ParamsContext";
import Cookies from "universal-cookie";
import BodyWeight from "./BodyWeight";
const BodyCompositionReport = () => {
	const [data, setData] = useState({});
	const [loading, setLoading] = useState(true);
	const cookies = new Cookies();
	const access_token = cookies.get("access_token");
	const { userData } = useContext(UserContext);
	const { client_id, user_id } = userData;
	const { date } = useContext(ParamsContext);
	useEffect(() => {
		(async () => {
			let end_time = new Date(date.endDate);
			end_time.setUTCHours(18, 29, 59, 999);
			const fetched_data = await getData(
				{
					client_id,
					access_token,
					"content-Type": "application/json",
					user_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
				},
				{
					params: ["body_weight"],
					user_id,
					start_time: new Date(date.startDate).toISOString(),
					end_time: end_time.toISOString(),
				},
				"getBodyComposition",
			);

			setData(fetched_data);
			setLoading(false);
		})();
	}, []);
	return loading ? (
		<Loading />
	) : data.status === "success" ? (
		<div className='flex font-smitchSans flex-col sm:w-[60%] w-[90%] m-auto mt-5 gap-10 '>
			<div className='flex items-center  gap-5'>
				<div className='shadow-inner border-2 flex  rounded-full w-[60px] h-[60px] items-center justify-center '>
					<img src={bodyComposition_icon} className='w-[30px]' alt='' />
				</div>
				<h2 className='text-xl font-bold'>Body Composition</h2>
			</div>
			<BodyWeight dataPoints={data.data.bodyComp.bodyWeightDataPoints} />
			<BodyFatPercentage dataPoints={data.data.bodyComp.bodyFatDataPoints} />
			<div className='flex flex-col w-[300px] '>
				<div className='flex justify-between'>
					<span className='text-sm font-light'>{data.data.bodyComp.item}</span>
					<span>
						<span>{data.data.bodyComp.newWeight}</span>{" "}
						{data.data.bodyComp.newWeightUnit}
					</span>
				</div>
				<div className='h-[1px] w-[260px] bg-slate-300'></div>
				<div className='flex justify-between'>
					<span className='text-sm font-light'>Weight Trend</span>
					<span>{data.data.bodyComp.weightTrend}</span>
				</div>
				<div className='h-[1px] w-[260px] bg-slate-300'></div>
				<div className='flex justify-between '>
					<span className='text-sm font-light'>Current Body Fat</span>
					<span>{data.data.bodyComp.bodyFat} %</span>
				</div>
				<div className='h-[1px] w-[260px] bg-slate-300'></div>
				<div className='flex justify-between '>
					<span className='text-sm font-light'>Body Fat Trend</span>
					<span>
						{data.data.bodyComp.bodyFatTrend
							? `${data.data.bodyComp.bodyFatTrend} %`
							: ""}
					</span>
				</div>
			</div>
		</div>
	) : (
		data.status === "failed" && (
			<div className='text-xl sm:w-[60%] w-[90%] m-auto'>
				Not able to get Body Composition Data
			</div>
		)
	);
};

export default BodyCompositionReport;
