import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { UserProvider } from "./contexts/UserContext.js";
import { ParamsProvider } from "./contexts/ParamsContext";
import { CookiesProvider } from "react-cookie";
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
	<React.StrictMode>
		<CookiesProvider>
			<ParamsProvider>
				<UserProvider>
					<App />
				</UserProvider>
			</ParamsProvider>
		</CookiesProvider>
	</React.StrictMode>,
);

