import "./App.css";
import React, { useState, useContext, useEffect } from "react";
import { BrowserRouter, Routes, Route,useLocation } from "react-router-dom";
import AllReports from "./pages/AllReports";
import Cookies from "universal-cookie";
import GenerateReport from "./pages/GenerateReport";
import { UserContext } from "./contexts/UserContext";
import { ParamsContext } from "./contexts/ParamsContext";
import Login from "./pages/Login";
import Protected from "./components/Protected";
import LoginRedirect from "./components/LoginRedirect";
import EcgReport from "./pages/EcgReport";
import Navigation from "./pages/Navigation";
import PageNotFound from "./pages/PageNotFound"

const { REACT_APP_CLIENT_ID } = process.env;
function App() {
	const cookies = new Cookies();
	const { userData, setUserData,setRedirectUrl } = useContext(UserContext);
	const { selected, date, setSelected, setDate } = useContext(ParamsContext);
	const queryParams = new URLSearchParams(window.location.search);
	useEffect(() => {
		const newData = {
			client_id: REACT_APP_CLIENT_ID,
			user_id: queryParams.get("user_id"),
		};
		setUserData(newData);
	}, []);

	useEffect(() => {
		const startDate = queryParams.get("startDate");
		const endDate = queryParams.get("endDate");
		let selected_params = queryParams.get("selected");
		const user_id = queryParams.get("user_id");
		if (startDate && endDate) {
			setDate({ startDate: startDate, endDate: endDate });
		}
		if (selected_params) {
			selected_params = selected_params.split(" ");
			let temp = [];
			for (let i = 0; i < selected_params.length; i++) {
				temp.push({ label: selected_params[i], value: selected_params[i] });
			}
			setSelected(temp);
		}
	}, [userData.user_id]);
	const [signed, setSigned] = useState(
		cookies.get("access_token") ? true : false,
	);
	const [mountLogin, setMountLogin] = useState(
		cookies.get("access_token") ? false : true,
	);
	
	
	return (
		<BrowserRouter>
			<Routes>
				<Route
					path='/reports'
					element={
						<Protected signed={signed}>
							<AllReports setSigned={setSigned} setMountLogin={setMountLogin} />
						</Protected>
					}
				/>
				<Route
					path='/'
					element={
						<LoginRedirect signed={mountLogin}>
							<Login setSigned={setSigned} />
						</LoginRedirect>
					}
				/>
				<Route
					path='/generate'
					element={
						<Protected signed={signed}>
							<GenerateReport
								setSigned={setSigned}
								setMountLogin={setMountLogin}
							/>
						</Protected>
					}
				/>

				<Route
					path='/allecg'
					element={
						<Protected signed={signed}>
							<EcgReport />
						</Protected>
					}
				/>
				<Route
					path='/navigate'
					element={
						<Protected signed={signed}>
							<Navigation setSigned={setSigned} setMountLogin={setMountLogin} />
						</Protected>
					}
				/>
				<Route path='*' element={<PageNotFound />} />
			</Routes>
			<GetURL/>
		</BrowserRouter>
	);
}

export default App;



const GetURL = () => {
	const cookies = new Cookies();
	const { setRedirectUrl } = useContext(UserContext);
	const location = useLocation();	
	  const geturl=()=> location.pathname + location.search;
	  useEffect(()=>{
		if(!cookies.get('access_token') && location.pathname!== "/"){
			setRedirectUrl(geturl())
		}
	  },[])
	  return <></>
  };