import { createContext, useState } from "react";
const ParamsContext = createContext();
const ParamsProvider = ({ children }) => {
	const [params, setParams] = useState([]);
	const [selected, setSelected] = useState([]);
	const [date, setDate] = useState({
		startDate: new Date(),
		endDate: new Date(),
	});
	const [ecgDate, setEcgDate] = useState({
		startDate: new Date(),
		endDate: new Date(),
	});
	return (
		<ParamsContext.Provider
			value={{
				params,
				setParams,
				selected,
				setSelected,
				date,
				setDate,
				ecgDate,
				setEcgDate,
			}}>
			{children}
		</ParamsContext.Provider>
	);
};
export { ParamsContext, ParamsProvider };
