import React, { useState, useEffect,useContext } from "react";
import vitals_icon from "../../assets/icons/vitals_icon.png";
import VitalsTable from "./VitalsTable";
import { getData } from "../../utils/apis";
import {UserContext} from '../../contexts/UserContext.js';
import { ParamsContext } from "../../contexts/ParamsContext";
import Loading from "../Loading";
import Cookies from "universal-cookie";
const VitalsReport = () => {
  const [data, setData] = useState({});
  let [loading,setLoading]= useState(true)
  const {userData}= useContext(UserContext);
  const {client_id,user_id}= userData;
  const {date}= useContext(ParamsContext)
  const cookies= new Cookies();
  const access_token=cookies.get("access_token");
  useEffect(() => {
    (async () => {
      let end_time= new Date(date.endDate);
      end_time.setUTCHours(18,29,59,999);
      const fetched_data =  await getData(
        {  
          client_id, 
          access_token,
          "content-Type": "application/json",
          user_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
        {
          params: ["blood_glucose", "blood_oxygen", "blood_pressure"],
          user_id ,
          start_time:  new Date(date.startDate).toISOString(),
          end_time:  end_time.toISOString(),
         },
         "getVitals"
       
      ); 
      
      setData(fetched_data);
      setLoading(false)
    })();
  }, []);
  return loading ? <Loading /> :
  data.status==="success" ? (
    <div className="flex font-smitchSans flex-col sm:w-[60%] w-[90%] m-auto mt-5 gap-10 ">
      <div className="flex items-center gap-5">
        <div className="shadow-2xl border-2 flex  rounded-full w-[60px] h-[60px] items-center justify-center ">
          <img src={vitals_icon} className="w-[30px] rounded-full" alt="" />
        </div>
        <h2 className="text-xl font-bold">Vitals</h2>
      </div>
      <VitalsTable {...data.data.avgBP} title="Blood Pressure" />
      <VitalsTable {...data.data.avgBG}  title="Blood Glucose"/>
      <VitalsTable {...data.data.avgBO}  title="Blood Oxygen Saturation"/>
    </div>
  ):(
    data.status==="failed"  && <div className="text-xl sm:w-[60%] w-[90%] m-auto">Not able to get Vitals Report Data</div>
  );
};

export default VitalsReport;
