import React, { useState, useContext, useEffect } from "react";
import nutrition_icon from "../../assets/icons/nutrition_icon.png";
import { getData } from "../../utils/apis";
import Loading from "../Loading";
import CaloriesConsumed from "./CaloriesConsumed";
import { UserContext } from "../../contexts/UserContext.js";
import { ParamsContext } from "../../contexts/ParamsContext";
import ConsumedCaloriesBreakdown from "./ConsumedCaloriesBreakdown";
import Cookies from "universal-cookie";
const NutritionReport = () => {
	const [data, setData] = useState({});
	const [loading, setLoading] = useState(true);
	const { userData } = useContext(UserContext);
	const { client_id, user_id } = userData;
	const { date } = useContext(ParamsContext);
	const cookies = new Cookies();
	const access_token = cookies.get("access_token");
	useEffect(() => {
		(async () => {
			let end_time = new Date(date.endDate);
			end_time.setUTCHours(18, 29, 59, 999);
			const fetched_data = await getData(
				{
					client_id,
					access_token,
					"content-Type": "application/json",
					user_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
				},
				{
					params: [],
					user_id,
					start_time: new Date(date.startDate).toISOString(),
					end_time: end_time.toISOString(),
				},
				"getNutrition",
			);
			setData(fetched_data);
			setLoading(false);
		})();
	}, []);
	return loading ? (
		<Loading />
	) : data.status === "success" ? (
		<div className='flex flex-col font-smitchSans sm:w-[60%] w-[90%] m-auto mt-5 gap-10 '>
			<div className='flex items-center  gap-5'>
				<div className='shadow-inner border-2 flex  rounded-full w-[60px] h-[60px] items-center justify-center '>
					<img src={nutrition_icon} className='w-[30px] ' alt='' />
				</div>
				<h2 className='text-xl font-bold'>Nutrition</h2>
			</div>
			{data?.data?.nutritionDataPoints && (
				<CaloriesConsumed
					dataPoints={
						data.data.nutritionDataPoints ? data.data.nutritionDataPoints : []
					}
					average={data.data.averageCalorie ? data.data.averageCalorie : null}
				/>
			)}
			{data?.data?.carbNutritionDataPoints && (
				<ConsumedCaloriesBreakdown
					carbs={data.data.carbNutritionDataPoints}
					proteins={data.data.proteinNutritionDataPoints}
					fibers={data.data.fiberNutritionDataPoints}
					fats={
						data.data.fatNutritionDataPoints
							? data.data.fatNutritionDataPoints
							: []
					}
				/>
			)}
			<div className='flex justify-around '>
				<div className='flex flex-col justify-between'>
					<span>Avg. Macro Value per day</span>
					<div className='w-[150px] flex justify-between text-sm font-light'>
						<span>Carbs</span>
						<span>{data?.data?.perDay.carb} g</span>
					</div>
					<div className='w-[150px] flex justify-between text-sm font-light'>
						<span>Proteins</span>
						<span>{data?.data?.perDay.protein} g</span>
					</div>
					<div className='w-[150px] flex justify-between text-sm font-light'>
						<span>Fibers</span>
						<span>{data?.data?.perDay.fiber} g</span>
					</div>
					<div className='w-[150px] flex justify-between text-sm font-light'>
						<span>Fats</span>
						<span>{data?.data?.perDay.fat} g</span>
					</div>
				</div>
				<div className='flex flex-col justify-between'>
					<span>Avg. Calories per Meal</span>
					<div className='w-[150px] flex justify-between text-sm font-light'>
						<span>Breakfast</span>
						<span>{data?.data?.perMeal.breakFast} Cals</span>
					</div>
					<div className='w-[150px] flex justify-between text-sm font-light'>
						<span>Lunch</span>
						<span>{data?.data?.perMeal.lunch} Cals</span>
					</div>
					<div className='w-[150px] flex justify-between text-sm font-light'>
						<span>Dinner</span>
						<span>{data?.data?.perMeal.dinner} Cals</span>
					</div>
					<div className='w-[150px] flex justify-between text-sm font-light'>
						<span>Others</span>
						<span>{data?.data?.perMeal.others} Cals</span>
					</div>
				</div>
			</div>
			<div className='text-xs flex gap-5 items-center'>
				<span>Avg. Calories Consumed per day</span>{" "}
				<span className='text-base'>
					{data?.data?.averageCalorie} <span className='text-xs'> Cals</span>{" "}
				</span>{" "}
			</div>
		</div>
	) : (
		data.status === "false" && (
			<div className='text-xl sm:w-[60%] w-[90%] m-auto'>
				Not able to get Nutrition Report
			</div>
		)
	);
};

export default NutritionReport;
