import React, { useState, useEffect, useContext } from "react";
import activity_icon from "../../assets/icons/activity_icon.png";
import { getData } from "../../utils/apis";
import Loading from "../Loading";
import CaloriesBurnedChart from "./CaloriesBurnedChart";
import ExerciseTimeChart from "./ExerciseTimeChart";
import { UserContext } from "../../contexts/UserContext.js";
import { ParamsContext } from "../../contexts/ParamsContext";
import HrZones from "./HrZones";
import Cookies from "universal-cookie";
const ActivityReport = () => {
	const [data, setData] = useState({});
	const [loading, setLoading] = useState(true);
	const { userData } = useContext(UserContext);
	const { client_id, user_id } = userData;
	const { date } = useContext(ParamsContext);
	const cookies = new Cookies();
	const access_token = cookies.get("access_token");
	useEffect(() => {
		(async () => {
			let end_time = new Date(date.endDate);
			end_time.setUTCHours(18, 29, 59, 999);
			const fetched_data = await getData(
				{
					client_id,
					access_token,
					"content-Type": "application/json",
					user_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
				},
				{
					params: [],
					user_id,
					start_time: new Date(
						new Date(date.startDate).toUTCString(),
					).toISOString(),
					end_time: end_time.toISOString(),
				},
				"getActivity",
			);
			setData(fetched_data);
			setLoading(false);
		})();
	}, []);
	return loading ? (
		<Loading />
	) : data.status === "success" ? (
		<div className='flex flex-col font-smitchSans sm:w-[60%] w-[90%] m-auto mt-5 gap-10 '>
			<div className='flex items-center  gap-5'>
				<div className='shadow-inner border-2 flex  rounded-full w-[60px] h-[60px] items-center justify-center '>
					<img src={activity_icon} className='w-[30px] ' alt='' />
				</div>
				<h2 className='text-xl font-bold'>Activity</h2>
			</div>
			{/* calories burned, exercise time ,HRZones chart     /////////// */}
			{data?.data?.calories?.calorieBurnedDataPoints && (
				<CaloriesBurnedChart
					dataPoints={data.data.calories.calorieBurnedDataPoints}
					average={data?.data?.calories.caloriePerDay}
				/>
			)}
			{data?.data?.exercise?.excerciseTimeDataPoints && (
				<ExerciseTimeChart
					dataPoints={data.data.exercise.excerciseTimeDataPoints}
					average={data?.data?.exercise.amount}
				/>
			)}
			<HrZones data={data?.data?.heartRateZones} />
			{/* ///////////////////////////////////////////////////////////////////////////// */}
			<div className='flex flex-col gap-5 sm:gap-0 sm:flex-row justify-around '>
				<div className='flex flex-col gap-1'>
					<div className='flex gap-5 '>
						<span className='text-sm font-light'> Calories Burned per day</span>
						<span className='text-sm font-light'>
							{" "}
							{data?.data?.calories?.caloriePerDay} Cals(avg){" "}
						</span>
					</div>
					<div className='h-[1px] w-[220px]  bg-slate-300'></div>
					<div className='flex gap-5'>
						<span className='text-sm font-light'>Exercise Time per day</span>
						<span className='text-sm font-light'>
							{data?.data?.calories?.exercisePerDay} mins(avg)
						</span>
					</div>
				</div>
				<div>
					<div className='flex flex-col justify-between'>
						<span>Avg. Time in HR Zones</span>
						<div className='w-[150px] flex justify-between text-sm font-light'>
							<span>Light</span>
							<span>
								{data?.data?.heartRateZones?.lightHeartRateZoneMinutes} mins
							</span>
						</div>
						<div className='w-[150px] flex justify-between text-sm font-light'>
							<span>Moderate</span>
							<span>
								{data?.data?.heartRateZones?.moderateHeartRateZoneMinutes} mins
							</span>
						</div>
						<div className='w-[150px] flex justify-between text-sm font-light'>
							<span>Intense</span>
							<span>
								{data?.data?.heartRateZones?.intenseHeartRateZoneMinutes} mins
							</span>
						</div>
						<div className='w-[150px] flex justify-between text-sm font-light'>
							<span>Peak</span>
							<span>
								{data?.data?.heartRateZones?.peakHeartRateZoneMinutes} mins
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	) : (
		data.status === "failed" && (
			<div className='text-xl sm:w-[60%] w-[90%] m-auto'>
				Not able to get Activity Report Data
			</div>
		)
	);
};

export default ActivityReport;
