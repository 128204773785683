import React, { useRef, useState } from "react";
import CanvasJSReact from "../../assets/canvasjs/canvasjs.react";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import { handleScrollLeft, handleScrollRight } from "../../utils/helper";

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const SleepStages = ({ data }) => {
	const [scrollPosition, setScrollPosition] = useState(0);
	const scrollableDiv = useRef(null);
	const handleScroll = () => {
		if (scrollableDiv.current) {
			setScrollPosition(scrollableDiv.current.scrollLeft);
		}
	};

	const hrRef = useRef(null);
	const {
		awakeSleepStageDataPoints,
		lightSleepStageDataPoints,
		remSleepStageDataPoints,
		deepSleepStageDataPoints,
	} = data;

	const options = {
		dataPointWidth: 5,
		marginBottom: 10,
		willReadFrequently: true,
		height: 135,
		animationEnabled: true,
		animationDuration: 1200,
		legend: {
			itemWidth: "120",
		},
		axisY: {
			minimum: 0,
			gridColor: "white",
			title: "",
			includeZero: true,
			tickLength: 0,

			labelFontColor: "gray",
			//    valueFormatString:" " ,
			lineThickness: 0,

			stripLines: [
				{
					thickness: 1,
					color: "gray",
					lineDashType: "longDash",
					labelPlacement: "inside",
					labelAlign: "far",
					labelWidth: 50,
					labelFontColor: "black",
					labelFontSize: 10,
				},
			],
		},
		axisX: {
			title: "",
			tickLength: 0,
			margin: 0,
			valueFormatString: " ",
			lineThickness: 0,
		},

		data: [
			{
				type: "stackedColumn",
				name: "Awake",
				color: "#EBEBEB",
				showInLegend: "true",

				dataPoints: awakeSleepStageDataPoints,
			},
			{
				type: "stackedColumn",
				name: "Light",
				color: "#5AC9F9",
				showInLegend: "true",
				dataPoints: lightSleepStageDataPoints,
			},
			{
				type: "stackedColumn",
				name: "REM",
				color: "#71F3F3",
				showInLegend: "true",
				dataPoints: remSleepStageDataPoints,
			},
			{
				type: "stackedColumn",
				name: "Deep",
				color: "#0053C7",
				showInLegend: "true",
				dataPoints: deepSleepStageDataPoints,
			},
		],
	};
	if (awakeSleepStageDataPoints.length > 30) {
		options.width = awakeSleepStageDataPoints.length * 40;
	}

	return (
		<div>
			<p className='text-md font-semibold'>Sleep Stages</p>
			<div
				ref={scrollableDiv}
				onScroll={handleScroll}
				className={
					awakeSleepStageDataPoints.length > 30
						? `w-full overflow-x-scroll scroll-smooth [&::-webkit-scrollbar]:hidden [-ms-overflow-style:'none'] [scrollbar-width:'none']`
						: `w-full`
				}>
				<CanvasJSChart options={options} ref={hrRef} />
				{/*You can get reference to the chart instance as shown above using onRef. This allows you to access all chart properties and methods*/}
			</div>
			<div className='flex w-full relative justify-between top-[-100px]'>
				<div>
					{scrollPosition !== 0 && (
						<BsArrowLeft
							onClick={() => {
								handleScrollLeft(
									scrollableDiv,
									setScrollPosition,
									scrollPosition,
								);
							}}
							size='25px'
							color='rgb(59 130 246)'
							className='cursor-pointer'
							disabled={scrollPosition === 0}
						/>
					)}
				</div>
				<div>
					{scrollPosition !== scrollableDiv?.current?.scrollWidth && (
						<BsArrowRight
							onClick={() => {
								handleScrollRight(
									scrollableDiv,
									setScrollPosition,
									scrollPosition,
								);
							}}
							color='rgb(59 130 246)'
							size='25px'
							className='cursor-pointer'
							disabled={
								scrollPosition ===
								scrollableDiv?.current?.scrollWidth -
									scrollableDiv?.current?.clientWidth
							}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default SleepStages;
