import React, { useState } from "react";
import CanvasJSReact from "../../assets/canvasjs/canvasjs.react";
import EcgModal from "./EcgModal.jsx";
let CanvasJS = CanvasJSReact.CanvasJS;
let CanvasJSChart = CanvasJSReact.CanvasJSChart;
const Ecg = ({ data }) => {
	const [showModal, setShowModal] = useState(false);

	let firstXAxisStripLinesArray = [];
	let secondXAxisStripLinesArray = [];
	let thirdXAxisStripLinesArray = [];

	let yAxisStripLinesArray = [];
	let stripLinesXAxisValue = {};
	let firstDataPointArray = data.first_pulse_data;
	let secondDataPointArray = data.second_pulse_data;
	let thirdDataPointArray = data.third_pulse_data;
	let color = "#BDBDBD";
	const option1 = {
		theme: "light2",
		axisY: {
			stripLines: yAxisStripLinesArray,
			maximum: 2,
			minumum: -1,
			interval: 0.5,
			tickThickness: 0,
			labelFormatter: function (e) {
				return "";
			},
		},
		axisX: {
			stripLines: firstXAxisStripLinesArray,
			gridColor: color,
			lineColor: color,
			tickThickness: 1,
			gridThickness: 1,
			lineThickness: 1.5,
			interval: 1,
			suffix: "s",
			minumum: 0,
			maximum: 10,
		},
		data: [
			{
				type: "spline",
				color: "#F75D5D",
				dataPoints: firstDataPointArray,
			},
		],
	};
	const option2 = {
		theme: "light2",
		axisY: {
			stripLines: yAxisStripLinesArray,
			maximum: 2,
			minumum: -1,
			interval: 0.5,
			tickThickness: 0,
			labelFormatter: function (e) {
				return "";
			},
		},
		axisX: {
			stripLines: secondXAxisStripLinesArray,
			gridColor: color,
			lineColor: color,
			tickThickness: 1,
			gridThickness: 1,
			lineThickness: 1,
			interval: 1,
			suffix: "s",
			minumum: 10,
			maximum: 20,
		},
		data: [
			{
				type: "spline",
				color: "#F75D5D",
				dataPoints: secondDataPointArray,
			},
		],
	};
	const option3 = {
		theme: "light2",
		axisY: {
			stripLines: yAxisStripLinesArray,
			maximum: 2,
			minumum: -1,
			interval: 0.5,
			tickThickness: 0,
			labelFormatter: function (e) {
				return "";
			},
		},
		axisX: {
			stripLines: thirdXAxisStripLinesArray,
			gridColor: color,
			lineColor: color,
			tickThickness: 1,
			gridThickness: 1,
			lineThickness: 1,
			interval: 1,
			suffix: "s",
			minumum: 20,
			maximum: 30,
		},
		data: [
			{
				type: "spline",
				color: "#F75D5D",
				dataPoints: thirdDataPointArray,
			},
		],
	};
	function firstAddStripLines() {
		for (let i = 0; i <= 10; i = i + 0.2) {
			firstXAxisStripLinesArray.push({
				value: parseFloat(i.toFixed(2)),
				thickness: 0.75,
				color: color,
			});
			stripLinesXAxisValue[i.toFixed(2)] = i;
		}
		for (let i = 0; i <= 10; i = i + 0.04) {
			if (!stripLinesXAxisValue[i.toFixed(2)]) {
				firstXAxisStripLinesArray.push({
					value: parseFloat(i.toFixed(2)),
					thickness: 0.5,
					color: color,
				});
			}
		}
	}
	function firstAddStripLines2() {
		//StripLines
		for (let i = 10; i <= 20; i = i + 0.2) {
			secondXAxisStripLinesArray.push({
				value: parseFloat(i.toFixed(2)),
				thickness: 0.75,
				color: color,
			});
			stripLinesXAxisValue[i.toFixed(2)] = i;
		}
		for (let i = 10; i <= 20; i = i + 0.04) {
			if (!stripLinesXAxisValue[i.toFixed(2)]) {
				secondXAxisStripLinesArray.push({
					value: parseFloat(i.toFixed(2)),
					thickness: 0.5,
					color: color,
				});
			}
		}
	}
	function firstAddStripLines3() {
		for (let i = 20; i <= 30; i = i + 0.2) {
			thirdXAxisStripLinesArray.push({
				value: parseFloat(i.toFixed(2)),
				thickness: 0.75,
				color: color,
			});
			stripLinesXAxisValue[i.toFixed(2)] = i;
		}
		for (let i = 20; i <= 30; i = i + 0.04) {
			if (!stripLinesXAxisValue[i.toFixed(2)]) {
				thirdXAxisStripLinesArray.push({
					value: parseFloat(i.toFixed(2)),
					thickness: 0.5,
					color: color,
				});
			}
		}
	}
	for (let i = -1; i <= 2.6; i = i + 0.1) {
		yAxisStripLinesArray.push({
			value: parseFloat(i.toFixed(1)),
			thickness: 0.5,
			color: color,
		});
	}
	firstAddStripLines();
	firstAddStripLines2();
	firstAddStripLines3();
	// loading ? <Loading /> :
	// data.status=="success" ?
	const Chart1 = () => {
		return <CanvasJSChart options={option1} />;
	};
	const Chart2 = () => {
		return <CanvasJSChart options={option2} />;
	};
	const Chart3 = () => {
		return <CanvasJSChart options={option3} />;
	};
	return (
		<div className='shadow-xl font-smitchSans border p-2 rounded-xl'>
			<h4 className='text-md font-semibold ml-2 text-gray-600'>
				{data.pulse_type} - {data.bpm} BPM Average
			</h4>
			<h4 className='text-xs ml-2  text-gray-400'>
				Recorded on {data.recorded_at}{" "}
			</h4>
			<h4 className='ml-2 mt-1 text-sm text-gray-600'>
				{data.is_normal
					? "This ECG does not show signs of atrial fibrillation"
					: "This ECG shows signs of Atrial Fibrillation. This means your heart is beating in an irregular pattern. We suggest you to talk to your doctor."}
			</h4>
			<Chart1 />
			<Chart2 />
			<Chart3 />
			<button
				className='bg-blue-600 hover:bg-blue-800 cursor-pointer m-3  self-center text-center text-white font-bold py-1 px-4 rounded-full'
				onClick={() => {
					setShowModal(true);
				}}>
				View In Full Screen
			</button>
			<EcgModal
				setShowModal={setShowModal}
				showModal={showModal}
				Chart1={Chart1}
				Chart2={Chart2}
				Chart3={Chart3}
			/>
		</div>
	);
};

export default Ecg;
