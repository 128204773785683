import { createContext, useState } from "react";
const UserContext = createContext();
const UserProvider = ({ children }) => {
	const [userData, setUserData] = useState({ client_id: "", user_id: "" });
	const [redirectUrl,setRedirectUrl]=useState(null)
	return (
		<UserContext.Provider value={{ userData, setUserData,redirectUrl , setRedirectUrl }}>
			{children}
		</UserContext.Provider>
	);
};
export { UserContext, UserProvider };
