import React, { useState, useEffect,useContext } from "react";
import { matchOtp } from "../../utils/apis.js";
import { useNavigate } from "react-router-dom";
import OTPInput, { ResendOTP } from "otp-input-react";
import Cookies from "universal-cookie";
import { Otpsent } from "../../utils/apis.js";
import {toast} from  "react-hot-toast";
import Toast from '../Toast.jsx';
import { UserContext } from "../../contexts/UserContext.js";
const renderButton = (buttonProps) => {
	return (
		<button
			className={`${
				buttonProps.remainingTime !== 0
					? "text-sm text-gray-400 "
					: "px-4 py-1 text-sm border rounded-xl bg-blue-600 text-white"
			}`}
			{...buttonProps}>
			{buttonProps.remainingTime !== 0
				? `${buttonProps.remainingTime} sec remaining`
				: "Resend"}
		</button>
	);
};
const renderTime = () => React.Fragment;
const Otp = ({ email, setSigned }) => {
	const [OTP, setOTP] = useState("");
	const navigate = useNavigate();
	const cookies = new Cookies();
	const {redirectUrl,setRedirectUrl}= useContext(UserContext)
	const handleChange = (newotp) => {
		setOTP(newotp);
	};
	useEffect(() => {
		if (OTP.length === 4) {
			(async () => {
				const response = await matchOtp(OTP, email);
				if (response?.data?.isOtpVerified === true) {
					cookies.set("access_token", response.data.access_token, {
						path: "/",
						maxAge: 604800,
					});
					setSigned(true);
				    if(redirectUrl){
						let temp= redirectUrl;
						setRedirectUrl(null)
						navigate(temp)
						return;
					}
					navigate("/navigate");
				} else {
					toast.error("wrong otp entered here");
				}
			})();
		}
	}, [OTP]);
	return (
		<div className='w-full font-smitchSans flex flex-col  gap-6 items-center'>
			<OTPInput
				secure={false}
				inputClassName='outline-none  !w-10 h-12 font- rounded-lg border border-gray-300  bg-white focus:bg-gray-50 focus:ring-1 ring-blue-700'
				value={OTP}
				onChange={handleChange}
				autoFocus
				OTPLength={4}
				otpType='number'
				disabled={false}
			/>
			<ResendOTP
				renderButton={renderButton}
				className='w-[55%]'
				maxTime={120}
				onResendClick={() => Otpsent(email)}
				renderTime={renderTime}
			/>
			<Toast/>
		</div>
	);
};
export default Otp;
