import React from "react";
//this component with render only one card at a time depending upon its item
const VitalTableCard = (props) => {
  switch (props.item.trim()) {
    case "Average Blood Pressure":
      return (
        <>
          <div className="flex items-center flex-row h-9 w-[100%]">
            <div className=" text-xs basis-1/2">{props.item}</div>
            <div className="flex flex-col justify-center items-center basis-1/6">
              <span className="text-sm font-semibold">
                {props.systolic ? props.systolic : "-"}
              </span>
              <span className="text-[10px]">Systolic</span>
            </div>
            <div className="flex flex-col justify-center items-center basis-1/6">
              <span className="text-sm font-semibold">
                {props.diastolic ? props.diastolic : "-"}
              </span>
              <span className="text-[10px]">Diastolic</span>
            </div>
            <div className="flex flex-col justify-center items-center basis-1/6">
              <span className="text-sm font-semibold">
                {props.dataset ? props.dataset : "-"}
              </span>
              <span className="text-[10px]">Data Set</span>
            </div>
          </div>
          <div className="h-[2px] w-full bg-slate-300"></div>
        </>
      );
    case "Highest Blood Pressure":
      return (
        <>
          <div className="flex items-center flex-row h-9 w-[100%]">
            <div className=" text-xs basis-1/2">{props.item}</div>
            <div className="flex flex-col justify-center items-center basis-1/6">
              <span className="text-sm font-semibold">
                {props.systolic ? props.systolic : "-"}
              </span>
              <span className="text-[10px]">Systolic</span>
            </div>
            <div className="flex flex-col justify-center items-center basis-1/6">
              <span className="text-sm font-semibold">
                {props.diastolic ? props.diastolic : "-"}
              </span>
              <span className="text-[10px]">Diastolic</span>
            </div>
            <div className="flex flex-col justify-center items-center basis-1/6">
              <span className="text-[10px]">{props.date?props.date:'-'}</span>
            </div>
          </div>
          <div className="h-[2px] w-full bg-slate-300"></div>
        </>
      );
    case "Lowest Blood Pressure":
      return (
        <>
          <div className="flex items-center flex-row h-9 w-[100%]">
            <div className=" text-xs basis-1/2">{props.item}</div>
            <div className="flex flex-col justify-center items-center basis-1/6">
              <span className="text-sm font-semibold ">
                {props.systolic ? props.systolic : "-"}
              </span>
              <span className="text-[10px]">Systolic</span>
            </div>
            <div className="flex flex-col justify-center items-center basis-1/6">
              <span className="text-sm font-semibold">
                {props.diastolic ? props.diastolic : "-"}
              </span>
              <span className="text-[10px]">Diastolic</span>
            </div>
            <div className="flex flex-col justify-center items-center basis-1/6">
              <span className="text-[10px]">{props.date?props.date:'-'}</span>
            </div>
          </div>
          <div className="h-[2px] w-full bg-slate-300"></div>
        </>
      );
    case "Blood Pressure Results Split":
      return (
        <>
          <div className="flex items-center flex-row h-9 w-[100%]">
            <div className=" text-xs basis-1/2">{props.item}</div>
            <div className="flex flex-col justify-center items-center basis-1/6">
              <span className="text-sm font-semibold text-green-500 ">
                {props.optimal ? props.optimal : "-"}
              </span>
              <span className="text-[10px]">Optimal</span>
            </div>
            <div className="flex flex-col justify-center items-center basis-1/6">
              <span className="text-sm font-semibold text-yellow-600">
                {props.moderate ? props.moderate : "-"}
              </span>
              <span className="text-[10px]">Moderate</span>
            </div>
            <div className="flex flex-col justify-center items-center basis-1/6">
              <span className="text-sm font-semibold text-red-600">{props.high ? props.high : "-"}</span>
              <span className="text-[10px]">High</span>
            </div>
          </div>
          <div className="h-[2px] w-full bg-slate-300"></div>
        </>
      );
    case "Average Blood Glucose":
      return (
        <>
          <div className="flex items-center flex-row h-9 w-[100%]">
            <div className=" text-xs basis-1/2">{props.item}</div>
            <div className="flex flex-col justify-center items-center basis-1/6">
              <span className="text-sm font-semibold "></span>
              <span className="text-[10px]"></span>
            </div>
            <div className="flex flex-col justify-center items-center basis-1/6">
              <span className="text-sm font-semibold">{props.bloodGlucose?props.bloodGlucose.toFixed(2):'-'}</span>
              <span className="text-[10px]">mg/dL</span>
            </div>
            <div className="flex flex-col justify-center items-center basis-1/6">
              <span className="text-sm font-semibold">{props.quantity?props.quantity:'-'}</span>
              <span className="text-[10px]">Data Set</span>
            </div>
          </div>
          <div className="h-[2px] w-full bg-slate-300"></div>
        </>
      );
    case "Highest Blood Glucose":
      return (
        <>
          <div className="flex items-center flex-row h-9 w-[100%]">
            <div className=" text-xs basis-1/2">{props.item}</div>
            <div className="flex flex-col justify-center items-center basis-1/6">
              <span className="text-sm font-semibold "></span>
              <span className="text-[10px]"></span>
            </div>
            <div className="flex flex-col justify-center items-center basis-1/6">
              <span className="text-sm font-semibold">{props.bloodGlucose?props.bloodGlucose:'-'}</span>
              <span className="text-[10px]">mg/dL</span>
            </div>
            <div className="flex flex-col justify-center items-center basis-1/6">
              <span className="text-[10px]">{props.date?props.date:''}</span>
            </div>
          </div>
          <div className="h-[2px] w-full bg-slate-300"></div>
        </>
      );
    case "Lowest Blood Glucose":
      return (
        <>
          <div className="flex items-center flex-row h-9 w-[100%]">
            <div className=" text-xs basis-1/2">{props.item}</div>
            <div className="flex flex-col justify-center items-center basis-1/6">
              <span className="text-sm font-semibold ">{props.bloodGlucose?props.bloodGlucose:'-'}</span>
              <span className="text-[10px]">Systolic</span>
            </div>
            <div className="flex flex-col justify-center items-center basis-1/6">
              <span className="text-sm font-semibold">{props.bloodGlucose?props.bloodGlucose:'-'}</span>
              <span className="text-[10px]">Diastolic</span>
            </div>
            <div className="flex flex-col justify-center items-center basis-1/6">
              <span className="text-[10px]">{props.date?props.date:'-'}</span>
            </div>
          </div>
          <div className="h-[2px] w-full bg-slate-300"></div>
        </>
      );
    case "Blood Glucose Results Split":
      return (
        <>
          <div className="flex items-center flex-row h-9 w-[100%]">
            <div className=" text-xs basis-1/2">{props.item}</div>
            <div className="flex flex-col justify-center items-center basis-1/6">
              <span className="text-sm font-semibold text-green-500 ">{props.optimal?props.optimal:'-'}</span>
              <span className="text-[10px]">Optimal</span>
            </div>
            <div className="flex flex-col justify-center items-center basis-1/6">
              <span className="text-sm font-semibold text-yellow-600">{props.moderate?props.moderate:"-"}</span>
              <span className="text-[10px]">Moderate</span>
            </div>
            <div className="flex flex-col justify-center items-center basis-1/6">
              <span className="text-sm font-semibold text-red-600">{props.high?props.high:"-"}</span>
              <span className="text-[10px]">High</span>
            </div>
          </div>
          <div className="h-[2px] w-full bg-slate-300"></div>
        </>
      );
    case "Average Blood Oxygen Saturation":
      return (
        <>
          <div className="flex items-center flex-row h-9 w-[100%]">
            <div className=" text-xs basis-1/2">{props.item}</div>
            <div className="flex flex-col justify-center items-center basis-1/6">
              <span className="text-sm font-semibold "></span>
              <span className="text-[10px]"></span>
            </div>
            <div className="flex flex-col justify-center items-center basis-1/6">
              <span className="text-sm font-semibold">{props.bloodOxygen?props.bloodOxygen:'-'}</span>
              <span className="text-[10px]">mg/dL</span>
            </div>
            <div className="flex flex-col justify-center items-center basis-1/6">
              <span className="text-sm font-semibold">{props.quantity?props.quantity:'-'}</span>
              <span className="text-[10px]">Data Set</span>
            </div>
          </div>
          <div className="h-[2px] w-full bg-slate-300"></div>
        </>
      );
    case "Highest Blood Oxygen Saturation":
      return (
        <>
          <div className="flex items-center flex-row h-9 w-[100%]">
            <div className=" text-xs basis-1/2">{props.item}</div>
            <div className="flex flex-col justify-center items-center basis-1/6">
              <span className="text-sm font-semibold "></span>
              <span className="text-[10px]"></span>
            </div>
            <div className="flex flex-col justify-center items-center basis-1/6">
              <span className="text-sm font-semibold">{props.bloodOxygen?props.bloodOxygen:'-'}</span>
              <span className="text-[10px]">mg/dL</span>
            </div>
            <div className="flex flex-col justify-center items-center basis-1/6">
              <span className="text-[10px]">{props.date}</span>
            </div>
          </div>
          <div className="h-[2px] w-full bg-slate-300"></div>
        </>
      );
    case "Lowest Blood Oxygen Saturation":
      return (
        <>
          <div className="flex items-center flex-row h-9 w-[100%]">
            <div className=" text-xs basis-1/2">{props.item}</div>
            <div className="flex flex-col justify-center items-center basis-1/6">
              <span className="text-sm font-semibold "></span>
              <span className="text-[10px]"></span>
            </div>
            <div className="flex flex-col justify-center items-center basis-1/6">
              <span className="text-sm font-semibold">{props.bloodOxygen?props.bloodOxygen:'-'}</span>
              <span className="text-[10px]">mg/dL</span>
            </div>
            <div className="flex flex-col justify-center items-center basis-1/6">
              <span className="text-[10px]">{props.date?props.date:'-'}</span>
            </div>
          </div>
          <div className="h-[2px] w-full bg-slate-300"></div>
        </>
      );
    case "Blood Oxygen Saturation Results Split":
      return (
        <>
          <div className="flex items-center flex-row h-9 w-[100%]">
            <div className=" text-xs basis-1/2">{props.item}</div>
            <div className="flex flex-col justify-center items-center basis-1/6">
              <span className="text-sm font-semibold text-green-500 ">{props.optimal ? props.optimal:'-'}</span>
              <span className="text-[10px]">Optimal</span>
            </div>
            <div className="flex flex-col justify-center items-center basis-1/6">
              <span className="text-sm font-semibold text-yellow-600">{props.moderate?props.moderate:"-"}</span>
              <span className="text-[10px]">Moderate</span>
            </div>
            <div className="flex flex-col justify-center items-center basis-1/6">
              <span className="text-sm font-semibold text-red-600">{props.high?props.high:"-"}</span>
              <span className="text-[10px]">High</span>
            </div>
          </div>
          <div className="h-[2px] w-full bg-slate-300"></div>
        </>
      );
      default :
      return null;
  }
};

export default VitalTableCard;
