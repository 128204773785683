import React, { useState, useEffect, useContext } from "react";
import Ecg from "../components/ECG/Ecg";
import Pagination from "../components/Pagination";
import SelectEcgTimeFrame from "../components/SelectEcgTimeFrame";
import Loading from "../components/Loading";
import { ParamsContext } from "../contexts/ParamsContext";
import { updateQueryParams } from "../utils/helper";
import { UserContext } from "../contexts/UserContext";

const EcgReport = () => {
	const { ecgDate } = useContext(ParamsContext);
	const [pageIndex, setPageIndex] = useState(0);
	const [ecgDateSet, setEcgDateSet] = useState(false);
	const [AllEcg, setAllEcg] = useState([]);
	let { userData } = useContext(UserContext);
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);
	document.title = `SmitchCare | ECG Report`;
	useEffect(() => {
		if (AllEcg.status === "success") {
			setData(
				AllEcg.data.ecg.ecgDataArray.slice(
					pageIndex * 4,
					AllEcg.data.ecg.ecgDataArray > pageIndex * 4 + 4
						? AllEcg.data.ecg.ecgDataArray - 1
						: pageIndex * 4 + 4,
				),
			);
		} else if (AllEcg.status === "failed") {
			setData([]);
		}
	}, [pageIndex, AllEcg, ecgDate.startDate]);

	useEffect(() => {
		updateQueryParams({
			user_id: userData.user_id,
			startDate: new Date(ecgDate.startDate).toISOString(),
			endDate: new Date(ecgDate.endDate).toISOString(),
		});
	}, [userData.user_id]);

	return !AllEcg?.status ? (
		<div className='flex flex-col font-smitchSans w-[400px] m-auto mt-[100px] gap-10 border pb-10 bg-white px-6  shadow-inner mx-auto max-w-lg rounded-2xl'>
			<h1 className='text-[30px] font-bold self-center mt-5'>
				Generate Ecg Report
			</h1>
			<h4 className='ml-3'>Select a time frame</h4>
			<div className='flex flex-col justify-center flex-1  '>
				<div className='flex flex-col flex-1 w-full gap-5 m-auto items-center'>
					<SelectEcgTimeFrame
						ecgDateSet={ecgDateSet}
						setLoading={setLoading}
						setEcgDateSet={setEcgDateSet}
						setAllEcg={setAllEcg}
					/>
				</div>
			</div>
		</div>
	) : !loading ? (
		AllEcg.status === "success" ? (
			<div className='flex flex-col font-smitchSans sm:w-[60%] w-[90%] m-auto mt-5 gap-10'>
				{/* logo section          ///////////////////////////////////////////////////////*/}
				<div className='flex justify-between'>
					<span className='flex justify-center items-center gap-2'>
						<img
							className='w-6'
							src='https://web.mysmitch.com/static/media/SMITCH.3427e991.png'
							alt=''
						/>
						<span className='text-sm font-bold'>Smitch Care</span>
					</span>
				</div>
				<div className='flex flex-col gap-1'>
					<h4 className='text-sm font-bold'>Suraj kumar</h4>
					<h4 className='text-sm font-medium'>
						Date of Birth: 13/05/1993 (29 years, 9 months)
					</h4>
					<div className='h-[2px] bg-slate-400 w-full'></div>
				</div>
				{AllEcg.data.ecg.ecgDataArray.length > 0 ? (
					data.map((el, index) => {
						return <Ecg key={index} pageIndex={pageIndex} data={el} />;
					})
				) : (
					<div> No Ecg Data </div>
				)}
				{AllEcg.data.ecg.ecgDataArray ? (
					<Pagination
						data={AllEcg.data.ecg.ecgDataArray.length}
						pageIndex={pageIndex}
						setPageIndex={setPageIndex}
					/>
				) : (
					<></>
				)}
			</div>
		) : (
			<div> No Ecg Data </div>
		)
	) : (
		<Loading />
	);
};

export default EcgReport;
