//exercise time chart
import React, { useRef, useState } from "react";
import CanvasJSReact from "../../assets/canvasjs/canvasjs.react";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import { handleScrollLeft, handleScrollRight } from "../../utils/helper";
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const ExerciseTimeChart = ({ dataPoints, average }) => {
	const [scrollPosition, setScrollPosition] = useState(0);
	const scrollableDiv = useRef(null);
	const exerciseRef = useRef(null);
	const color = "green";

	const handleScroll = () => {
		if (scrollableDiv.current) {
			setScrollPosition(scrollableDiv.current.scrollLeft);
		}
	};

	const options = {
		dataPointWidth: 5,
		animationEnabled: true,
		animationDuration: 1200,
		willReadFrequently: true,
		marginBottom: 10,
		dataPointRadius: 2,
		height: 135,
		axisY: {
			minimum: 0,
			gridColor: "white",
			title: "",
			includeZero: true,
			tickLength: 0,
			labelFontColor: "gray",
			lineThickness: 0,
			labelPlacement: "outside",

			stripLines: [
				{
					thickness: 1,
					color: "gray",
					value: average,
					label: average + " avg",
					lineDashType: "longDash",
					labelPlacement: "inside",
					labelAlign: "far",
					labelWidth: 50,
					labelFontColor: "black",
					labelFontSize: 10,
				},
			],
		},
		axisX: {
			title: "",
			tickLength: 0,
			marginLeft: 10,
			valueFormatString: " ",
			lineThickness: 0,
		},
		data: [
			{
				// Change type to "doughnut", "line", "splineArea", etc.
				type: "column",
				color,
				radius: 10,

				dataPoints,
			},
		],
	};
	if (dataPoints.length > 30) {
		options.width = dataPoints.length * 40;
	}
	return (
		<div data-testid='exercise-time-chart'>
			<h2 className='text-md font-semibold'>Exercise Time</h2>
			<div
				ref={scrollableDiv}
				onScroll={handleScroll}
				className={
					dataPoints.length > 30
						? `w-full overflow-x-scroll scroll-smooth [&::-webkit-scrollbar]:hidden [-ms-overflow-style:'none'] [scrollbar-width:'none']`
						: `w-full`
				}>
				<CanvasJSChart options={options} ref={exerciseRef} />
			</div>
			<div className='flex w-full relative justify-between top-[-100px]'>
				<div>
					{scrollPosition !== 0 && (
						<BsArrowLeft
							onClick={() => {
								handleScrollLeft(
									scrollableDiv,
									setScrollPosition,
									scrollPosition,
								);
							}}
							size='25px'
							color='rgb(59 130 246)'
							className='cursor-pointer'
							disabled={scrollPosition === 0}
						/>
					)}
				</div>
				<div>
					{scrollPosition !== scrollableDiv?.current?.scrollWidth && (
						<BsArrowRight
							onClick={() => {
								handleScrollRight(
									scrollableDiv,
									setScrollPosition,
									scrollPosition,
								);
							}}
							color='rgb(59 130 246)'
							size='25px'
							className='cursor-pointer'
							disabled={
								scrollPosition ===
								scrollableDiv?.current?.scrollWidth -
									scrollableDiv?.current?.clientWidth
							}
						/>
					)}
				</div>
			</div>
		</div>
	);
};
export default ExerciseTimeChart;
