import React, { useState, useEffect, useContext } from "react";
import { getData } from "../../utils/apis.js";
import Loading from "../Loading.jsx";
import { UserContext } from "../../contexts/UserContext.js";
import { ParamsContext } from "../../contexts/ParamsContext";
import Cookies from "universal-cookie";

const UserDetails = () => {
	const { REACT_APP_CLIENT_ID } = process.env;
	const [data, setData] = useState({});
	const cookies = new Cookies();
	const access_token = cookies.get("access_token");
	const client_id = REACT_APP_CLIENT_ID;
	const [loading, setLoading] = useState(true);
	const queryParams = new URLSearchParams(window.location.search);
	let {
		userData: { user_id },
	} = useContext(UserContext);
	if (!user_id) {
		user_id = queryParams.get("user_id");
	}
	let { date } = useContext(ParamsContext);
	if (date?.endDate - date?.endDate <= 10000) {
		date = {
			startDate: queryParams.get("startDate"),
			endDate: queryParams.get("endDate"),
		};
	}
	useEffect(() => {
		(async () => {
			let end_time = new Date(date.endDate);
			end_time.setUTCHours(18, 29, 59, 999);
			const fetched_data = await getData(
				{
					client_id,
					access_token,
					"content-Type": "application/json",
					user_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
				},
				{
					params: ["body_weight", "body_height", "body_temperature", "sleep"],
					user_id,
					start_time: new Date(
						new Date(date.startDate).toUTCString(),
					).toISOString(),
					end_time: end_time.toISOString(),
				},
				"getOverallStats",
			);
			setData(fetched_data);
			setLoading(false);
		})();
	}, []);

	return loading ? (
		<Loading />
	) : data?.status == "success" ? (
		<div className='flex font-smitchSans flex-col sm:w-[60%] w-[90%] m-auto mt-5 gap-10'>
			{/* logo section          ///////////////////////////////////////////////////////*/}
			<div className='flex justify-between'>
				<span className='flex justify-center items-center gap-2'>
					<img
						className='w-6'
						src='https://web.mysmitch.com/static/media/SMITCH.3427e991.png'
						alt=''
					/>
					<span className='text-sm font-bold'>Smitch Care</span>
				</span>
				<div className='flex justify-center items-center gap-2'>
					<span className='text-sm font-bold'>Report ID</span>
					<span className='text-sm'>{data.data.summary.report_id}</span>
				</div>
			</div>
			{/* description section      /////////////////////////////////////////// */}
			<div className='flex flex-col gap-5'>
				<div>
					<h2 className='text-lg font-bold'>{data.data.summary.name}</h2>
					<h6 className='text-sm  text-gray-400'>{data.data.summary.dob}</h6>
					<h6 className='text-sm capitalize text-gray-400'>
						{data.data.summary.gender}
					</h6>
				</div>
				<div>
					<h2 className='text-lg font-bold'>Holistic Health Report</h2>
					<h6 className='text-sm  text-gray-400'>
						{data.data.summary.start_time} to {data.data.summary.end_time}
					</h6>
					<h6 className='text-xs  text-gray-400'>
						Generated on {data.data.summary.date}
					</h6>
				</div>
			</div>
			{/* Stats section              ////////////////////////////////////*/}
			<div>
				<h2 className='text-lg font-bold'>Overall Stats</h2>
				<div className='py-5 border-b-2 border-b-gray-300'>
					{/* vitals */}
					<h3 className='text-md font-semibold mb-2'>Vitals</h3>
					<div className='grid grid-cols-2 gap-y-4  '>
						<div className='flex gap-4'>
							<img
								src='https://smitchcare.azureedge.net/blood.svg'
								className='w-4'
								alt='blood'
							/>
							<div>
								<h4 className='text-sm font-light mb-1'>
									{data.data.avgBP.avgBP.item}
								</h4>
								<p className='text-sm font-light'>
									{data.data.avgBP.avgBP.systolic &&
									data?.data?.avgBP?.avgBP.diastolic ? (
										<>
											<span className='text-[26px] font-medium mr-1'>
												{data.data.avgBP.avgBP.systolic}/
												{data.data.avgBP.avgBP.diastolic}
											</span>
											mmHg
										</>
									) : (
										<>-</>
									)}
								</p>
							</div>
						</div>
						<div className='flex gap-4'>
							<img
								src='https://smitchcare.azureedge.net/blood.svg'
								className='w-4'
								alt='blood'
							/>
							<div>
								<h4 className='text-sm font-light mb-1'>
									{data.data.avgBG.avgBG.item}
								</h4>
								<p className='text-sm font-light'>
									{data?.data?.avgBG?.avgBG.bloodGlucose ? (
										<>
											<span className='text-[26px] font-medium mr-1'>
												{+data.data.avgBG.avgBG.bloodGlucose.toFixed(2)}
											</span>
											{data.data.avgBG.avgBG.bloodGlucoseUnit}
										</>
									) : (
										<>-</>
									)}
								</p>
							</div>
						</div>
						<div className='flex gap-4'>
							<img
								src='https://smitchcare.azureedge.net/blood.svg'
								className='w-4'
								alt='blood'
							/>
							<div>
								<h4 className='text-sm font-light mb-1'>
									{data.data.avgBO.avgBO.item}
								</h4>
								<p className='text-sm font-light'>
									{data.data.avgBO.avgBO.bloodOxygen ? (
										<>
											<span className='text-[26px] font-medium mr-1'>
												{data.data.avgBO.avgBO.bloodOxygen}
											</span>
											%
										</>
									) : (
										<>-</>
									)}
								</p>
							</div>
						</div>
					</div>
				</div>
				{/* body measurements */}

				<div className='py-5 border-b-2 border-b-gray-300'>
					<h3 className='text-md font-semibold mb-3'>Body Measurements</h3>
					<div className='grid grid-cols-2 '>
						<div className='flex gap-4'>
							<img
								height='20px'
								width='20px'
								src='https://smitchcare.azureedge.net/scale.svg'
								className='w-4'
								alt='scaleIcon'
							/>
							<div>
								<h4 className='text-sm font-light mb-1'>
									{data.data.currentWE.item}
								</h4>
								<p className='text-sm font-light'>
									<span className='text-[26px] font-medium'>
										{data.data.currentWE.newWeight}{" "}
									</span>
									{data.data.currentWE.newWeightUnit}
								</p>
							</div>
						</div>
						<div className='flex gap-4'>
							<img
								height='20px'
								width='20px'
								src='https://smitchcare.azureedge.net/scale.svg'
								className='w-4'
								alt='scaleIcon'
							/>
							<div>
								<h4 className='text-sm font-light mb-1'>
									{data.data.avgBT.item}
								</h4>
								<p className='text-sm font-light'>
									<span className='text-[26px] font-medium'>
										{data.data.avgBT.avgTemperature}
									</span>{" "}
									{data?.data?.avgBT?.newavgTemperatureUnit}
								</p>
							</div>
						</div>
					</div>
				</div>
				{/* sleep */}
				<div className='py-5 border-b-2 border-b-gray-300'>
					<h3 className='text-md font-semibold mb-3'>Sleep</h3>
					<div className='grid grid-cols-2 '>
						<div className='flex gap-4'>
							<img
								height='20px'
								width='20px'
								src='https://smitchcare.azureedge.net/bed.svg'
								className='w-4'
								alt='scaleIcon'
							/>
							<div>
								<h4 className='text-sm font-light mb-1'>
									{data.data.sleep.avgSleep.item}
								</h4>
								<p className='text-sm font-light'>
									<span className='text-[26px] font-medium'>
										{data.data.sleep.avgSleep.sleepHour}{" "}
									</span>
									hr
									<span className='text-[26px] font-medium ml-1'>
										{data.data.sleep.avgSleep.sleepMin}{" "}
									</span>
									mins
								</p>
							</div>
						</div>
						<div className='flex gap-4'>
							<img
								height='20px'
								width='20px'
								src='https://smitchcare.azureedge.net/bed.svg'
								className='w-4'
								alt='scaleIcon'
							/>
							<div>
								<h4 className='text-sm font-light mb-1'>
									{data.data.sleep.avgBedTime.item}
								</h4>
								<p className='text-sm font-light'>
									<span className='text-[26px] font-medium'>
										{data?.data?.sleep?.avgBedTime?.bedTime &&
											data.data.sleep.avgBedTime.bedTime.split(" ")[0]}
									</span>{" "}
									{}
									{data?.data?.sleep?.avgBedTime?.bedTime &&
										data.data.sleep.avgBedTime.bedTime.split(" ")[1]}
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	) : (
		<div className='text-xl sm:w-[60%] w-[90%] m-auto'>
			Not able to get UserDetails
		</div>
	);
};

export default UserDetails;
